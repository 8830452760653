<template>
  <b-card>
    <form @submit.prevent="validateBeforeSubmit">
        <h1>Edit Profile</h1>

        <p class="text-muted">Enter manager information</p>
        <b-input-group class="mt-3 mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="text" v-validate="'required'" name="fname" v-model="fname" class="form-control" placeholder="First Name" />
        </b-input-group>
        <i v-show="errors.has('fname')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('fname')" class="help is-danger">The first name is required.</span>
        <b-input-group class="mt-3 mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="text" v-validate="'required'" name="lname" v-model="lname" class="form-control" placeholder="Last Name" />
        </b-input-group>
        <i v-show="errors.has('lname')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('lname')" class="help is-danger">The last name is required.</span>
        <hr>

        <b-input-group class="mt-3 mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-phone fa-flip-horizontal"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="number" v-model="phone" v-validate="'required|digits:10'" name="phone" class="form-control" placeholder="Phone Number" />
        </b-input-group>
        <i v-show="errors.has('phone')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('phone')" class="help is-danger">{{ errors.first('phone') }}</span>

        <b-input-group class="mt-3 mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-fax"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="text" v-model="fax" name="fax" v-validate="'digits:10'" class="form-control" placeholder="Fax Number" />
        </b-input-group>
        <i v-show="errors.has('fax')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('fax')" class="help is-danger">{{ errors.first('fax') }}</span>

        <hr>

        <b-input-group class="mt-3 mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="email" v-model="email" v-validate="'required'" name="email" class="form-control" placeholder="Email" />
        </b-input-group>
        <i v-show="errors.has('email')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('email')" class="help is-danger">The email is required.</span>
        <i v-show="error" class="fa fa-exclamation-triangle"></i><span v-show="error" class="help is-danger">Error. Please contact admin.</span>

        <b-button variant="success" class="mt-4" type="submit" block>Edit Profile</b-button>
      </form>
  </b-card>
</template>

<script>
export default {
  name: 'AdminEditProfile',
  data: function () {
    return {
      fname: '',
      lname: '',
      email: '',
      fax: '',
      phone: '',
      error: false,
    }
  },
  mounted: function () {
    this.$http.get('/manager/get/profile', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.$session.get('jwt')
      }
    })
    .then(response => {
      if (response.status === 200) {
        this.fname = response.data.fname;
        this.lname = response.data.lname;
        this.email = response.data.email;
        this.phone = response.data.phone;
        this.fax = response.data.fax;
      }
    })
  },
  methods: {
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = JSON.stringify({
            fname: this.fname,
            lname: this.lname,
            email: this.email,
            phone: this.phone,
            fax: this.fax,
          })
          this.$http.post('/manager/edit/profile', data)
          .then(response => {
            if (response.status === 200) {
              this.$toasted.show('Successfully Updated', {icon: 'fa-check', type: 'success', duration: '3000'})
              this.$router.go(-1)
            }
          })
          .catch(error => {
            this.$toasted.show('Error. Please Try Again.', {type: 'error', duration: '3000'})
            this.error = true;
          })
          return;
        }
      });
    },
  }
}
</script>
